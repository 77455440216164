export function isMinVersion(versionString, minVersionString = "6.0.0") {
  if (!versionString) return false;
  const versionArray = versionString.split(".");
  const minVersionArray = minVersionString.split(".");

  for (let i = 0; i < minVersionArray.length; i++) {
    const versionPart = parseInt(versionArray[i]) || 0;
    const minVersionPart = parseInt(minVersionArray[i]) || 0;

    if (versionPart > minVersionPart) {
      return true;
    } else if (versionPart < minVersionPart) {
      return false;
    }
  }

  return true;
}
