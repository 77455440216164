import { lazy } from "react";

export const retryLazy = (componentImport, name = "") =>
  lazy(
    () =>
      new Promise((resolve, reject) => {
        const refreshedName = `refreshed_v${process.env.REACT_APP_VERSION}${name ?? `_${name}`}`;
        let pageAlreadyRefreshed = false;
        // Aos 5.46 이전 버전에서 DOM Storage 사용이 불가능해 분기처리.
        // @TODO 추후 앱 강업 후 해당 코드 개선 필요
        let saveLocalStorage = true;
        try {
          pageAlreadyRefreshed = JSON.parse(localStorage.getItem(refreshedName) || "false");
        } catch (error) {
          pageAlreadyRefreshed = true;
          saveLocalStorage = false;
        }

        // try to import the component
        componentImport()
          .then(component => {
            resolve(component);
          })
          .catch(error => {
            if (!pageAlreadyRefreshed) {
              saveLocalStorage && localStorage.setItem(refreshedName, "true");
              return window.location.reload();
            }
            reject(error);
          });
      })
  );
