import { checkMobilePlatform } from "utils/user";
import * as Sentry from "@sentry/react";

// app > close view event
export const appClose = () => {
  try {
    const platform = checkMobilePlatform();
    if (platform === "ios") {
      window.location.href = `scriptHandler://closeBrowser`;
    } else if (platform === "aos") {
      window.scriptHandler?.closeBrowser && window.scriptHandler.closeBrowser();
    } else {
      window.history.back();
    }
  } catch (e) {
    Sentry.captureException(`appClose : ${JSON.stringify(e.response?.data?.message ?? e?.message)}`);
  }
};

// session_webview와 파라미터 동일 (파라미터로 상단 네이게이터, 타이틀 조작 가능함)
export const openBrowser = (pathname, params) => {
  const searchParams = new URLSearchParams(params).toString();
  const url = pathname.startsWith("http") ? pathname : `${window.location.origin}${pathname}${searchParams ? `?${searchParams}` : ""}`;

  const platform = checkMobilePlatform();
  if (platform === "ios") {
    window.webkit.messageHandlers.openBrowser.postMessage(JSON.stringify({ url, isBottomSafeArea: false }));
  } else if (platform === "aos") {
    window.scriptHandler && window.scriptHandler.openBrowser(url);
  } else {
    window.location.href = url;
  }
};

export const doJavascriptOnPage = (pathname, eventName, param = "", isClose = true) => {
  const url = pathname.startsWith("http") ? pathname : `${window.location.origin}${pathname}`;

  const platform = checkMobilePlatform();
  if (platform === "ios") {
    const jsonObj = {
      url,
      "javascript": `eventFromApp('${eventName}', ${JSON.stringify(param)})`,
    };
    window.webkit.messageHandlers.doJavascriptOnPage.postMessage(JSON.stringify(jsonObj));
  } else if (platform === "aos") {
    window.scriptHandler && window.scriptHandler.doJavascriptOnPage(url, `eventFromApp('${eventName}', ${JSON.stringify(param)})`);
  } else {
  }

  isClose && appClose();
};

export const showToast = msg => {
  try {
    if (!["number", "string"].includes(typeof msg) || !msg) return;

    const platform = checkMobilePlatform();
    if (platform === "ios") {
      window.webkit.messageHandlers?.showToast?.postMessage(msg);
    } else if (platform === "aos") {
      window.scriptHandler && window.scriptHandler?.showToast(msg);
    } else {
    }
  } catch (e) {
    Sentry.captureException(`showToast : ${JSON.stringify(e.response?.data?.message ?? e?.message)}`);
  }
};

// 뷰티솔루션(스캐너) 사진/갤러리 불러오기
export const appUploadPhoto = () => {
  const platform = checkMobilePlatform();
  if (platform === "ios") {
    window.webkit.messageHandlers.uploadPhoto.postMessage(JSON.stringify({ "completion": "window.setPhoto" }));
  } else if (platform === "aos") {
    window.aiBeautyHandler && window.aiBeautyHandler.uploadPhoto(JSON.stringify({ "completion": "window.setPhoto" }));
  }
};

// 뷰티솔루션(스캐너) 가상성형 요청
export const appUploadReshapePhoto = (data = {}) => {
  const platform = checkMobilePlatform();
  if (platform === "ios") {
    window.webkit.messageHandlers.uploadReshapePhoto.postMessage(JSON.stringify({ "completion": "window.setReshapePhoto", ...data }));
  } else if (platform === "aos") {
    window.aiBeautyHandler && window.aiBeautyHandler.uploadReshapePhoto(JSON.stringify({ "completion": "window.setReshapePhoto", ...data }));
  }
};

// 뷰티솔루션(스캐너) api 저장완료시 클라이언트에 콜백용
export const appChangeReshapePhotoStatus = () => {
  const platform = checkMobilePlatform();
  if (platform === "ios") {
    window.webkit.messageHandlers.changeReshapePhotoStatus.postMessage("");
  } else if (platform === "aos") {
    window.aiBeautyHandler && window.aiBeautyHandler.changeReshapePhotoStatus();
  }
};
