const initialState = {
  show: false,
};

function modal_reducer(state = initialState, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { show: true, ...action };
    case "CLOSE_MODAL":
      return { initialState };
    default:
      return state;
  }
}

export default modal_reducer;
