import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

export const name = "bottomsheet";
const slice = createSlice({
  name,
  initialState: { ...initialState },
  reducers: {
    add: (state, { payload }) => {
      state.list.push({ ...payload, id: +new Date() });
    },
    pop: state => {
      state.list.pop();
    },
    clear: state => {
      state.list = [];
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
