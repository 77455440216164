// ios/aos
export const checkMobilePlatform = () => {
  let varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
  if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
    return "ios";
  }
  if (varUA.indexOf("android") > -1) {
    return "aos";
  } else {
    return "other";
  }
};

export const isMobile = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|OperaMini/i.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
};
