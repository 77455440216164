import { combineReducers } from "@reduxjs/toolkit";
import modal_reducer from "reducers/modal";
import * as toast from "./toast";
import * as bottomsheet from "./bottomsheet";

const rootReducer = combineReducers({
  [bottomsheet.name]: bottomsheet.reducer,
  [toast.name]: toast.reducer,
  modal_reducer,
});

export default rootReducer;
