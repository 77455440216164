import React from "react";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingContent = ({ loading = true }) => (
  <Modal open={loading} BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0)" } }}>
    <Fade in={loading} style={{ outline: "none" }}>
      <Wrapper>
        <CircularProgress sx={{ color: "#7264FF" }} />
      </Wrapper>
    </Fade>
  </Modal>
);

export default LoadingContent;
