export const getCookie = name => {
  let value = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return value ? value[2] : null;
};

export const removeCookie = name => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  document.cookie = name + "=; Max-Age=0";
};

export const getParam = name => {
  let params = window.location.search.substr(window.location.search.indexOf("?") + 1);
  let val = "";
  params = params.split("&");
  for (let i = 0; i < params.length; i++) {
    let temp = params[i].split("=");
    if ([temp[0]] === name) {
      val = temp[1];
    }
  }
  return val;
};

export const numberWithCommas = n => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const phoneNumberFormet = n => {
  return n.toString().replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
};
